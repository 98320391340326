import Paragraph from "../../components/layout/Paragraph";

export default function Motivation() {
  return (
    <Paragraph
      headline="Motivation"
      headlineFontSize="14pt"
      style={{ fontSize: "12pt" }}
    >
      <div>
        In this section, I seek to validate the project's scope and direction by
        collecting opinions.
      </div>
      <div>
        A fundamental belief of mine is that there exists a widespread desire
        for individuals to
        <ol>
          <li>engage more actively in technology</li>
          <li>
            crafting their own personalized tools and services to suit their
            unique needs
          </li>
          <li>
            automating aspects of their daily routines on a personal level
          </li>
        </ol>
      </div>
      <div>
        Further, certain personal needs may not be significant / sustainable
        enough for businesses to address, or the data involved may be too
        sensitive to entrust to third parties.
      </div>
    </Paragraph>
  );
}

/*
 With this section, I would like to validate the scope of this project by
      collecting opinions. One of my core assumptions is that, I think more
      people would like to get involved in tech, build their personal tools and
      services, according their need, and automate parts of their daily lives on
      very individual scale, if it would not be such a hassle. Further I
      believe, some these personal use cases would not lucrative or important
      enough for business to cove, or the data that is being processed is much
      too sensitive to hand it to a third-party and by this sharing control and
      ownership. But let's focus on the first assumption.
*/
