import { Component, ReactElement } from "react";

import { s, t } from "../../styles";
import SelectorField from "./SelectorField";
import SidechainSelector from "./SidechainSelector";
import {
  Action,
  RuntimeFlowOutputOptions,
  SidechainRoute,
  isValidFlowOption,
} from "../../types";
import { BoardContextState } from "../../BoardContext";

const none = "No Output";

type Props = {
  items: Array<string>;
  value: string | null;
  children: JSX.Element | Array<JSX.Element | null>;
  flow: RuntimeFlowOutputOptions | undefined;
  boardContext: BoardContextState;
  sidechainRouting: Array<SidechainRoute>;
  id: string;
  disabled?: boolean;

  onSidechangeRouting: (route: Array<SidechainRoute>) => void;
  onAction: (action: Action) => void;
  onSelect: (item: string | null) => void;
  onExpand?: () => void;
  onCollapse?: () => void;
};

type State = {
  expanded: boolean;
};

export default class OutputOptions extends Component<Props, State> {
  state = { expanded: false };

  componentDidUpdate(prevPops: Props, prevState: State) {
    if (prevState.expanded !== this.state.expanded) {
      this.props.onAction({ type: "update-peers" });
    }
  }

  getSelectorOptions = () => {
    const { items } = this.props;
    return items.reduce((a, c) => ({ ...a, [c]: c }), {
      [none]: none,
    });
  };

  renderOptions = (label: string, selector: ReactElement) => {
    return (
      <div style={s(t.p10)}>
        <h1 style={s(t.tl, t.fs11, t.pt0, t.mb5)}>{label}</h1>
        {selector}
      </div>
    );
  };

  renderPeerOptions = () => {
    const { onSelect, onAction, value = "pass" } = this.props;
    const peerOptions = this.getSelectorOptions();
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {this.renderOptions(
          "Route to",
          <SelectorField
            label="Peer"
            options={peerOptions}
            value={value || none}
            onChange={(ev, { value: selected }) => {
              onSelect(selected === none ? null : selected);
            }}
            onOpen={() => onAction({ type: "update-peers" })}
          />
        )}
        <div style={{ paddingBottom: 20 }}>{this.props.children}</div>
      </div>
    );
  };

  renderFlowOptions = () => {
    const { onAction, flow = "pass" } = this.props;
    return this.renderOptions(
      "Flow",
      <SelectorField
        label="Flow"
        options={{
          pass: "pass",
          stop: "stop",
          // stopIf: 'stop-if'
        }}
        value={flow}
        onChange={(ev, { value: flow }) => {
          isValidFlowOption(flow) && onAction({ type: "flow-changed", flow });
        }}
      />
    );
  };

  renderSidechainOptions = () => {
    const {
      boardContext,
      sidechainRouting = [],
      onSidechangeRouting,
    } = this.props;
    return this.renderOptions(
      "Sidechain",
      <SidechainSelector
        boardContext={boardContext}
        values={sidechainRouting}
        onChange={(routing) => onSidechangeRouting(routing)}
      />
    );
  };

  renderExpanded = () => {
    const { disabled } = this.props;
    const { expanded } = this.state;
    return (
      <div
        style={{
          opacity: expanded ? 1 : 0,
          width: expanded ? 320 : 0,
          transition: "width 0.5s, opacity 0.5s",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {this.renderFlowOptions()}
        {this.renderSidechainOptions()}
        {disabled ? (
          <div style={{ ...t.p(10, 25) }}>{this.props.children}</div>
        ) : (
          this.renderPeerOptions()
        )}
      </div>
    );
  };

  render() {
    const { expanded } = this.state;
    const { id, onExpand, onCollapse } = this.props;
    return (
      <div
        id={`output-options-${id}`}
        style={s(
          t.ls1,
          t.fs13,
          {
            height: 320,
            marginTop: 10,
          },
          {
            border: "solid 1px lightgray",
            borderRight: "none",
            borderRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
          {
            display: "flex",
            flexDirection: "row",
          }
        )}
      >
        <button
          style={{
            height: "100%",
            border: "none",
            textAlign: "center",
            backgroundColor: expanded ? "gray" : "#efefef",
          }}
          onClick={() => {
            this.setState({ expanded: !expanded }, () => {
              if (expanded) {
                onCollapse && onCollapse();
              } else {
                onExpand && onExpand();
                const elem = document.getElementById(`output-options-${id}`);
                if (elem) {
                  setTimeout(
                    () => elem.scrollIntoView({ behavior: "smooth" }),
                    400
                  );
                }
              }
            });
          }}
        >
          <div
            className="hkp-fnt-family"
            style={s(t.ls2, t.fs11, {
              transform: "rotate(-90deg)",
              marginTop: 35,
              width: 8,
              color: expanded ? "white" : "#333",
            })}
          >
            Output
          </div>
        </button>
        {this.renderExpanded()}
      </div>
    );
  }
}
