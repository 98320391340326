import { Component } from "react";
import { Input, Checkbox } from "semantic-ui-react";

import { MapDescriptor } from "hkp-services";

import InputLabelValue from "../../../components/shared/InputLabelValue";
import SelectorField from "../../../components/shared/SelectorField";
import Button from "../../../components/shared/Button";

import ServiceUI from "../../services/ServiceUI";
import { s, t } from "../../../styles";

const newProperty = { key: "value" };

export class MapUI extends Component {
  state = {
    template: {},
    conditionEnabled: false,
  };

  onInit(initialState) {
    const { template, condition, mode } = initialState;
    this.setState({
      template,
      condition,
      conditionEnabled: !!condition,
      mode,
    });
  }

  onNotification = (service, notification) => {
    const { template, mode } = notification;
    if (template !== undefined) {
      this.setState({ template });
    }

    if (mode !== undefined) {
      this.setState({ mode });
    }
  };

  renderMapping = (service) => {
    const { template = {}, mode } = this.state;
    const modeOptions = {
      replace: "replace",
      add: "add",
      overwrite: "overwrite",
    };
    return (
      <div
        style={{
          width: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SelectorField
          label="Mode"
          options={modeOptions}
          value={mode}
          onChange={async (_, { value: mode }) => {
            await service.configure({ mode });
            this.setState({ mode });
          }}
        />
        <div style={{ height: "5px" }} />
        <div style={s(t.tl, t.ls1, t.fs12, t.mt5)}>Template</div>
        <div
          style={{
            border: "solid 1px lightgray",
            borderRadius: 3,
            padding: 5,
            marginBottom: 5,
          }}
        >
          {Object.keys(template)
            .filter((key) => typeof template[key] !== "object")
            .map((key, idx) => (
              <InputLabelValue
                key={`${service.uuid}-${key}-${idx}`}
                label={key}
                labelPosition="left"
                value={template[key]}
                onCommit={({ label: newKey, value }) => {
                  const updatedTemplate = Object.keys(template).reduce(
                    (all, cur) =>
                      cur !== key
                        ? { ...all, [cur]: template[cur] } // this is the input that changed
                        : newKey !== ""
                        ? { ...all, [newKey]: value }
                        : all, // empty key means deleting the item
                    {}
                  );
                  service.configure({ template: updatedTemplate });
                  this.setState({ template: updatedTemplate });
                }}
              />
            ))}
          <div>
            <Button
              style={{
                marginTop: "3px",
                width: "100%",
              }}
              onClick={() =>
                this.setState({
                  template: {
                    ...this.state.template,
                    ...newProperty,
                  },
                })
              }
            >
              Add Property
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderCondition = (service) => {
    return (
      <div style={{ textAlign: "left" }}>
        <Checkbox
          label="Apply Condition"
          checked={this.state.conditionEnabled}
          onChange={(_, { checked: conditionEnabled }) => {
            this.setState({ conditionEnabled });
            service.configure({
              condition: conditionEnabled ? this.state.condition : null,
            });
          }}
        />
        <Input
          style={{ margin: "5px 0px", width: "100%" }}
          type="text"
          disabled={!this.state.conditionEnabled}
          value={this.state.condition || ""}
          onChange={(_, { value }) => {
            this.setState({ condition: value });
          }}
          onKeyPress={(e) =>
            e.key === "Enter" &&
            service.configure({ condition: this.state.condition })
          }
        />
      </div>
    );
  };

  render() {
    return (
      <ServiceUI
        {...this.props}
        onInit={this.onInit.bind(this)}
        onNotification={this.onNotification.bind(this)}
        segments={[
          { name: "Mapping", render: this.renderMapping },
          // { name: 'Condition', render: this.renderCondition }
        ]}
      />
    );
  }
}

const descriptor = {
  serviceName: MapDescriptor.serviceName,
  serviceId: MapDescriptor.serviceId,
  create: (app, board, descriptor, id) =>
    new MapDescriptor.service(app, board, descriptor, id),
  createUI: MapUI,
};

export default descriptor;
