import { CSSProperties } from "react";

export const fontStyle: CSSProperties = {
  fontSize: 14,
  letterSpacing: 1,
};

export const detailStyle: CSSProperties = {
  fontSize: 12,
  textTransform: "none",
  paddingLeft: 10,
  letterSpacing: 1,
};
