const { parse, eval: expEval } = require("expression-eval");
const moment = require("moment");

function reformatDate(date, inputFormat, outputFormat) {
  return moment(date, inputFormat).format(outputFormat);
}

function parseExpression(f) {
  try {
    return parse(f);
  } catch (err) {
    return "syntax-error";
  }
}

const globalScope = {
  print: console.log,
  log: console.log,
  round: Math.round,
  sin: Math.sin,
  min: Math.min,
  max: Math.max,
  rand: Math.random,
  number: (x) => Number(x),
  string: (x) => `${x}`,
  stringify: JSON.stringify,
  parse: JSON.parse,
  concat: (x, y) => `${x}${y}`,
  reformatDate,
  blobToUint8Array: async (x) => new Uint8Array(await x.arrayBuffer()),
  slice: (arr, offset, step, end) =>
    arr.slice(offset, end).filter((x, i) => i % step === 0),
  sum: (x) => (x && x.reduce ? x.reduce((acc, v) => acc + v, 0) : x),
  avg: (x) => (x && x.reduce ? x.reduce((acc, v) => acc + v, 0) / x.length : x),
  arrayToAudioBuffer: (arr) => {
    const audioCtx = new AudioContext();
    const buffer = audioCtx.createBuffer(1, arr.length, audioCtx.sampleRate);
    const channel = buffer.getChannelData(0);
    arr.forEach((x, i) => (channel[i] = x));
    return buffer;
  },
  fromQueryParam: (name) => {
    const qp = new URLSearchParams(window.location.search);
    return qp.get(name);
  },
  fromStorage: (name) => localStorage.getItem(name),
};

function evalExpression(ast, params) {
  if (ast === "syntax-error") {
    return "syntax-error";
  }

  return expEval(ast, { ...params, ...globalScope });
}

function evilEval(buffer, params) {
  const script = `
    async function hkp(){
      const { params, ...globals } = this;
      ${buffer}
    }
    return hkp.bind({ ...this })();
  `;
  const f = Function(script);
  return f.bind({ ...params, ...globalScope, params })(params);
}

module.exports = {
  evilEval,
  parseExpression,
  evalExpression,
};
