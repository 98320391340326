import Article from "../../components/layout/Article";
import Paragraph from "../../components/layout/Paragraph";

import Imprint from "../about/Imprint";

export default function Terms() {
  return (
    <Article title="Terms and Conditions" date="8.3.2024">
      <Paragraph>
        Welcome and thank you for your interest my private project and website
        at <a href="https://hookup.to">hookup.to</a>. These terms and conditions
        of service form a legally binding contract between you and me concerning
        your use of the Service. Please read the following terms carefully. By
        using, downloading, installing, or otherwise accessing or using the
        Service, you agree that you have read and understood, and, as a
        condition to your use of the Service, you agree to be bound by, the
        following terms and conditions, including the privacy policy (together,
        these 'terms'). If you are not eligible or do not agree to the terms,
        then you do not have my permission to use the Service. Your use of the
        Service constitutes an agreement, and you agree to be bound by these
        terms.
      </Paragraph>
      <Paragraph headline="Overview">
        The website serves as a platform for constructing logic (further denoted
        as 'Boards') through modular building blocks. Users have the ability to
        locally create and run Boards, subsequently storing them and uploading
        to the cloud. The platform, along with example Boards and remote
        runtimes, constitutes integral parts of the service.
      </Paragraph>
      <Paragraph headline="Using Boards">
        The service is offered to anyone interested in constructing logic and
        collaborating with others. It is not intended for any illegal
        activities, whether using the service's resources or those of others.
        The primary purpose is for personal, non-commercial use. I reserve the
        right, at my sole discretion, to suspend or terminate the accounts of
        users who primarily use or access the service for other purposes.
      </Paragraph>
      <Paragraph headline="Eligibility">
        To use the service, you must be at least 13 years old. By agreeing to
        these terms, you represent and warrant that: (a) you are at least 13
        years old; (b) if you are 13 to 17 years of age, your parent or guardian
        has affirmatively consented to your access and use of the service; (c)
        you have not previously been suspended or removed from the service; and
        (d) your registration and use of the service comply with all applicable
        laws and regulations. If you are an entity, organization, or company (an
        'Organization'), the individual accepting these terms on your behalf
        represents and warrants that they have the authority to bind you to
        these terms, and you agree to be bound by them.
      </Paragraph>
      <Paragraph headline="Accounts and Registration">
        To access most features of the service, you must register for an
        account. During the registration process, you may be required to provide
        information about yourself, such as your name, email address, or other
        contact details. By registering, you agree that the information you
        provide is accurate, complete, and not misleading, and that you will
        keep it accurate and up-to-date. As part of the registration, you will
        create a password. It is your sole responsibility to maintain the
        confidentiality of your account and password, and you accept
        responsibility for all activities that occur under your account. If you
        believe that your account is no longer secure, please get in touch.
      </Paragraph>
      <Paragraph headline="New Features">
        I may, at my sole discretion from time to time, introduce new features
        to the service. These features will be considered part of the service,
        and all provisions of this agreement relating to the service will apply.
        All features may include partially functional or non-functional
        elements. You acknowledge that access to all features is optional, and
        if you choose to use any feature, you agree to do so at your own risk. I
        reserve the right to terminate any features at any time, without
        warning, and without any liability to you.
      </Paragraph>
      <Paragraph headline="Licenses">
        Subject to your complete and ongoing compliance with these Terms, I
        grant you, solely for your personal use, a limited, non-exclusive,
        non-transferable, non-sublicensable, revocable license to:
        <ol>
          <li>Access and use the Website and Platform.</li>
          <li>
            Download and deploy remote runtimes within the given quotas and for
            your personal usage.
          </li>
        </ol>
      </Paragraph>
      <Paragraph headline="Produced Data">
        ou can use the platform to generate new data. You are solely responsible
        for your use of any data produced using the platform.
      </Paragraph>
      <Paragraph headline="License Restrictions">
        Except and solely to the extent such a restriction is impermissible
        under applicable law, you may not: (a) reproduce, distribute, publicly
        display, publicly perform, or create derivative works of the service;
        (b) make modifications to the service; or (c) interfere with or
        circumvent any feature of the service, including any security or access
        control mechanism. If applicable law prohibits your use of the service,
        then you may not use it.
      </Paragraph>

      <Paragraph headline="Ownership">
        Proprietary Rights. The Service is owned and operated by me. All
        elements of the Service, including but not limited to the design,
        software, text, graphics, and other materials, are protected by
        intellectual property laws and are owned by me. You agree not to
        reproduce, distribute, modify, or create derivative works based on the
        Service without my explicit permission.
      </Paragraph>
      <Paragraph headline="Content">
        Certain features of the service may permit users to submit, upload,
        publish, broadcast, or otherwise transmit content to the service. You
        retain any copyright and other proprietary rights that you may hold in
        the User Content that you upload to the service, subject to the licenses
        granted in these terms. However, you acknowledge and agree that if you
        are an employee of an organization, the applicable organization may own
        the rights to that user content. By providing user content via the
        service, you affirm, represent, and warrant to me that:
        <ul>
          <li>
            You are the creator and owner of the user content or have the
            necessary licenses, rights, consents, and permissions to use it
            within the platform.
          </li>
          <li>
            Data, including its processing, will not: (a) infringe, violate,
            misappropriate, or otherwise breach any third-party right, including
            any copyright, trademark, patent, trade secret, moral right, privacy
            right, right of publicity, or any other intellectual property,
            contract, or proprietary right; (b) slander, defame, libel, or
            invade the right of privacy, publicity, or other property rights of
            any other person; or (c) cause me to violate any law or regulation
            or require me to obtain any further licenses from or pay any
            royalties, fees, compensation, or other amounts or provide any
            attribution to any third parties.
          </li>
          <li>
            Your user content could not be deemed by a reasonable person to be
            objectionable, profane, indecent, pornographic, harassing,
            threatening, embarrassing, hateful, or otherwise inappropriate.
          </li>
        </ul>
      </Paragraph>
      <Paragraph headline="Monitoring Data">
        I do not control and do not have any obligation to monitor: (a) user
        content; (b) any content made available by third parties; or (c) the use
        of the service by its users. You acknowledge and agree that I reserve
        the right to, and may from time to time, monitor any and all information
        transmitted or received through the service for operational and other
        purposes. If, at any time, I choose to monitor the content, then I still
        assume no responsibility or liability for the content or any loss or
        damage incurred as a result of the use of content. During monitoring,
        information may be examined, recorded, copied, and used in accordance
        with the <a href="/privacy">Privacy Policy</a>. I may block, filter,
        remove, or disable access to any user content transmitted through the
        service without any liability to the user who uploaded such user content
        to the service or to any other users of the service.
      </Paragraph>
      <Paragraph headline="Code of Conduct">
        By using the service, you agree not to:
        <ul>
          <li>
            Use the service for any illegal purpose or in violation of any
            local, state, national, or international law or my Acceptable Use
            Policy.
          </li>
          <li>
            Harass, threaten, demean, embarrass, bully, or otherwise harm any
            other user of the service.
          </li>
          <li>
            Violate, encourage others to violate, or provide instructions on how
            to violate any right of a third party, including by infringing or
            misappropriating any third-party intellectual property right.
          </li>
          <li>
            Access, search, or otherwise use any portion of the service through
            the use of any engine, software, tool, agent, device, or mechanism
            (eg. spiders, robots, crawlers, and data mining tools) other than
            those provided by mechanisms.
          </li>
          <li>
            Interfere with security-related features of the service, including
            by: (a) disabling or circumventing features that prevent or limit
            the use, printing, or copying of any content; or (b) reverse
            engineering or otherwise attempting to discover the source code of
            any portion of the service except to the extent that the activity is
            expressly permitted by applicable law.
          </li>

          <li>
            Perform any fraudulent activity, including impersonating any person
            or entity, claiming a false affiliation or identity, accessing any
            other service account without permission, or falsifying your age or
            date of birth.
          </li>
        </ul>
      </Paragraph>
      <Paragraph headline="Termination">
        If you violate any material provision of these terms, your authorization
        to access the service and these terms automatically terminate. Upon
        termination of these terms:
        <ol>
          <li>
            Your license rights will terminate, and you must immediately cease
            all use of the service.
          </li>
          <li>
            You will no longer be authorized to access your account or the
            service.
          </li>
        </ol>
        You are solely responsible for retaining copies of any user content you
        upload to the service since, upon termination of your account, you may
        lose access rights to any user content you uploaded to the service. If
        your account has been terminated for a breach of these terms, you are
        prohibited from creating a new account on the service using a different
        name, email address, or other forms of account verification.
      </Paragraph>
      <Paragraph headline="Changes">
        I reserve the right to modify or discontinue all or any portion of the
        service at any time, temporarily or permanently, without notice to you -
        including by limiting or discontinuing certain features of the service.
        I will have no liability for any change to the service, including any
        paid-for functionalities, or any suspension or termination of your
        access to or use of the service. It is advisable to retain copies of any
        user content you upload to the service so that you have permanent copies
        in the event the service is modified in such a way that you lose access
        to uploaded user content.
      </Paragraph>
      <Paragraph headline="Indemnity">
        To the maximum extent permitted by law, you are accountable for your
        utilization of the service.
      </Paragraph>
      <Paragraph headline="Disclaimers">
        The service and all materials and content available through the service
        are provided 'as is' and on an 'as available' basis. I disclaim all
        warranties of any kind, whether express or implied, relating to the
        service and all materials and content available through the service,
        including:
        <ol>
          <li>
            Any implied warranty of merchantability, fitness for a particular
            purpose, title, quiet enjoyment, or non-infringement.
          </li>
          <li>
            Any warranty arising out of course of dealing, usage, or trade.
          </li>
        </ol>
        <p>
          I do not warrant that the service or any portion of the service, or
          content offered through the service, will be uninterrupted, secure, or
          free of errors, viruses, or other harmful components. I do not warrant
          that any of those issues will be corrected. You understand and agree
          that you use any portion of the service at your own discretion and
          risk, and that I am not responsible for any damage to your property
          (including your computer system or mobile device used in connection
          with the service) or any loss of data, including user content.
        </p>
        <p>
          You acknowledge and agree that I will not be liable for any mistakes,
          inaccuracies, omissions, or offensive material in the generated
          content. You rely upon the generated data at your sole risk. To the
          fullest extent permitted by law, in no event will I be liable to you
          for any indirect, incidental, special, consequential, or punitive
          damages.
        </p>
        <p>
          I am under no obligation to provide support for the service. In
          instances where support may be offered, it will be subject to
          published policies.
        </p>
        <p>
          The service is intended for visitors located within the EU. We make no
          representation that the service is appropriate or available for use
          outside of the EU. Access to the service from countries, territories,
          or by individuals where such access is illegal is prohibited.
        </p>
      </Paragraph>
      <Paragraph headline="Privacy Policy.">
        Please read the
        <a href="/privacy">Privacy Policy</a> carefully for information relating
        to the collection, use, storage, and disclosure of your personal
        information.
      </Paragraph>
      <Paragraph headline="Imprint">
        <Imprint />
      </Paragraph>
      <Paragraph headline="Credits">
        <div style={{ textAlign: "left" }}>
          <div>
            Icons are from
            <a href="https://fontawesome.com/license/free"> Font Awesome </a>
          </div>
          <div>
            Special Elite is used as a{" "}
            <a href="https://fonts.google.com/specimen/Special+Elite/about">
              font
            </a>
          </div>
          <div>
            Using{" "}
            <a href="https://microsoft.github.io/monaco-editor/">Monaco</a> as
            code editor
          </div>
          <div>
            <a href="https://react.dev/">React</a> and{" "}
            <a href="https://react.semantic-ui.com/">Semantic-UI React</a> are
            used
          </div>
        </div>
      </Paragraph>
    </Article>
  );
}
