import { ExampleService, ExampleServiceUI } from "./ExampleService";
import Runtime from "../../../components/Runtime";

type Props = {
  runtimeName: string;
};

export default function RuntimeBlock({ runtimeName }: Props) {
  const service = new ExampleService();
  const services = [
    <ExampleServiceUI
      key="example-service-1"
      service={service}
      setup={() => service}
    />,
  ];
  const runtime = {
    services,
  };
  const boardContext = {
    services,
    registry: [],
    isRuntimeInScope: () => false,
  };
  return (
    <div
      style={{
        marginTop: 20,
        marginRight: 30,
        width: 300,
      }}
    >
      <Runtime
        runtime={runtime}
        runtimeName={runtimeName}
        services={services}
        boardContext={boardContext}
      />
    </div>
  );
}
