import * as uuid from "uuid";

import { replacePlaceholders } from "../../core/url";
import {
  BoardDescriptor,
  OutputRoute,
  PeerInputRouting,
  PeerOutputRouting,
  PlaygroundBoard,
  PlaygroundTemplate,
  RuntimeDescriptor,
  RuntimeServiceMap,
  ServiceDescriptor,
} from "../../types";
import { decodeBoardState } from "./BoardLink";

export async function importBoard(
  url: string
): Promise<BoardDescriptor | null> {
  try {
    const res = await fetch(url);

    const data: any = await res.json();
    const runtimeIds = Object.keys(data);
    return {
      runtimes: runtimeIds.map((rid, ridx) => ({
        id: rid,
        type: data[rid].type,
        name: data[rid].name || `Runtime ${ridx + 1}`,
        bundles: data[rid].bundles,
      })),
      services: runtimeIds.reduce(
        (all, rid) => ({
          ...all,
          [rid]: data[rid].services.map((svc: ServiceDescriptor) => {
            if (!svc.uuid) {
              return { ...svc, uuid: uuid.v4() };
            }
            return svc;
          }),
        }),
        {}
      ),
      registry: data.registry || {},
    };
  } catch (err) {
    console.error("Import failed", err, url);
  }
  return null;
}

export async function createBoardFromTemplate(
  templateUrl: string,
  params: any
): Promise<PlaygroundBoard | null> {
  const resp = await fetch(templateUrl);
  if (!resp || !resp.ok) {
    return null;
  }
  try {
    const template: PlaygroundTemplate = await resp.json();
    const {
      runtimes,
      services,
      sidechainRouting,
      description,
      inputRouting,
      outputRouting,
      registry,
    } = template;
    const patchedRuntimes = runtimes.map((rt) => ({
      ...rt,
      id: uuid.v4(),
    }));

    const patchedServices = patchedRuntimes.reduce<RuntimeServiceMap>(
      (all, rt, idx) => ({
        ...all,
        [rt.id]: services[runtimes[idx].id].map((svc) => ({
          ...svc,
          uuid: uuid.v4(),
        })),
      }),
      {}
    );

    const partchedSidechainRouting = sidechainRouting
      ? patchedRuntimes.reduce((all, rt, idx) => {
          const originalRt = runtimes[idx];
          const originalRuntimeSidechain =
            sidechainRouting[originalRt.id] || [];
          const patchedRuntimeSidechain = originalRuntimeSidechain.map(
            (entry) => {
              const origRTIndex = runtimes.findIndex(
                (r) => r.id === entry.runtimeId
              );
              if (origRTIndex === -1) {
                console.error(
                  "createBoardFromTemplate(): could not find runtime",
                  entry,
                  runtimes
                );
              }
              const origSvcIndex = services[entry.runtimeId].findIndex(
                (svc) => svc.uuid === entry.serviceUuid
              );
              if (origSvcIndex === -1) {
                console.error(
                  "createBoardFromTemplate(): could not find service",
                  entry,
                  services[originalRt.id]
                );
              }
              const patchedRuntimeId = patchedRuntimes[origRTIndex].id;
              return {
                runtimeId: patchedRuntimeId,
                serviceUuid:
                  patchedServices[patchedRuntimeId][origSvcIndex].uuid,
              };
            }
          );
          return {
            ...all,
            [rt.id]: patchedRuntimeSidechain,
          };
        }, {})
      : {};

    const patchedInputRouting = inputRouting
      ? patchedRuntimes.reduce<PeerInputRouting>(
          (all, rt, idx) => ({
            ...all,
            [rt.id]: {
              hostDescriptor: null,
              route: {
                peer:
                  replacePlaceholders(inputRouting[runtimes[idx].id], params) ||
                  null,
              },
            },
          }),
          {}
        )
      : {};

    const patchedOutputRouting = outputRouting
      ? patchedRuntimes.reduce<PeerOutputRouting>((all, rt, idx) => {
          const routing: OutputRoute = outputRouting[runtimes[idx].id];
          return routing
            ? {
                ...all,
                [rt.id]: {
                  hostDescriptor: null,
                  route: {
                    ...routing,
                    peer: routing.peer
                      ? replacePlaceholders(routing.peer, params) || null
                      : null,
                  },
                },
              }
            : all;
        }, {})
      : {};

    return {
      runtimes: patchedRuntimes,
      services: patchedServices,
      sidechainRouting: partchedSidechainRouting,
      inputRouting: patchedInputRouting,
      outputRouting: patchedOutputRouting,
      description,
      registry,
    };
  } catch (err) {
    console.error("Creating from template failed", templateUrl, err);
    return null;
  }
}

export function reorderService(
  allServices: RuntimeServiceMap,
  runtime: RuntimeDescriptor,
  serviceUuid: string,
  targetPosition: number
) {
  const services = allServices[runtime.id];
  const fromIndex = services.findIndex((svc) => serviceUuid === svc.uuid);
  const toIndex =
    targetPosition > fromIndex
      ? Math.max(targetPosition - 1, 0)
      : targetPosition;

  const newArr = [...services];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, services[fromIndex]);
  return newArr;
}

export function importFromLink(fromLink: string) {
  const src: string = decodeBoardState(fromLink);
  return JSON.parse(src);
}
