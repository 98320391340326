import { Link } from "react-router-dom";
import NavigateButton from "../NavigateButton";

export default function MissionPage() {
  return (
    <div
      className="fade-in-linear-slow"
      style={{ width: "100%", height: "100%" }}
    >
      <div
        style={{
          height: "100%",
          width: "80%",
          marginLeft: "10%",
          display: "table",
          tableLayout: "fixed",
        }}
      >
        {[
          {
            q: "What",
            a: "An attempt to lower access barriers and play with technology",
            href: "/docs/pitch/what",
          },
          {
            q: "Why",
            a: "Technology is an enabler for creativity, inspiration and thinking",
            href: "/docs/pitch/why",
          },
          {
            q: "How",
            a: "Build abstractions that seamlessly connect data, logic, devices and services",
            href: "/docs/pitch/how",
          },
          {
            a: (
              <NavigateButton
                destination="/welcome/pitch"
                text="Quick Pitch"
                style={{ color: "#454f6e", fontSize: 14, border: "1px white" }}
              />
            ),
          },
        ].map((x, xi) => (
          <div
            key={xi}
            style={{
              textAlign: "center",
              display: "table-row",
            }}
          >
            <div
              style={{
                display: "table-cell",
                verticalAlign: "middle",
                textAlign: "center",
              }}
              className={`hkp-fnt-family fade-in-${xi}`}
            >
              {x.q && (
                <h1
                  className="hkp-fnt-family"
                  style={{ fontSize: 19, color: "454f6d", paddingBottom: 5 }}
                >
                  <Link to={x.href} style={{ fontSize: 17 }}>
                    {x.q}
                  </Link>
                </h1>
              )}
              <div
                className="home-title hkp-fnt-family"
                style={{
                  paddingTop: 2,
                  paddingLeft: 2,
                  fontSize: 14,
                  color: "#777",
                  textTransform: "none",
                  letterSpacing: 2,
                }}
              >
                {x.a}
                {x.href && (
                  <Link
                    className="hkp-fnt-family"
                    to={x.href}
                    style={{
                      fontSize: 10,
                      paddingLeft: 3,
                    }}
                  >
                    {" show more"}
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
