import Template from "../../../Template";

import { Section, Paragraph, Text } from "../../Elements";

export default function Remotes() {
  return (
    <Template title="remote runtimes" parent="Side tracks">
      <div style={{ margin: "40px 0px" }}>
        <Section title="Setup remote runtime">
          <Text>
            <Paragraph>TODO</Paragraph>
          </Text>
        </Section>
      </div>
    </Template>
  );
}
