export default function Dots() {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        marginBottom: 10,
      }}
    >
      ...
    </div>
  );
}
