import Board from "./Board";

export default function Sketch() {
  return (
    <div
      style={{
        width: "95%",
        margin: "auto",
        marginTop: 20,
      }}
    >
      <Board />
    </div>
  );
}
