const moment = require("moment");
const { sleep } = require("./helpers");

const serviceId = "hookup.to/service/timer";
const serviceName = "Timer";

class Timer {
  constructor(app, board, descriptor, id) {
    this.uuid = id;
    this.board = board;
    this.app = app;

    this.__timer = undefined;
    this.counter = 0;
    this.periodic = false;
    this.periodicValue = 1;
    this.periodicUnit = "s";
    this.oneShotDelay = 0;
    this.running = false;
  }

  clearTimer() {
    if (this.__timer) {
      clearInterval(this.__timer);
      this.__timer = undefined;
      this.counter = 0;
      this.app.notify(this, { running: false, count: this.counter });
    }
  }

  configure(config) {
    const {
      periodicValue,
      periodicUnit,
      periodic,
      oneShotDelay,
      immediate,
      counter,
      running,
      until,
      // commands
      stop,
      start,
      restart,
    } = config;
    let doStop =
      stop || restart || (this.running && running !== undefined && !running);
    let doStart = start || restart;

    if (periodicValue !== undefined) {
      this.periodicValue = periodicValue;
      if (doStart === undefined && running) {
        doStart = true;
      }
    }

    if (periodicUnit !== undefined) {
      this.periodicUnit = periodicUnit;
      if (doStart === undefined && running) {
        doStart = true;
      }
    }

    if (periodic !== undefined) {
      this.periodic = periodic;
      if (doStart === undefined && running) {
        doStart = true;
      }
    }

    if (oneShotDelay !== undefined) {
      this.oneShotDelay = oneShotDelay;
    }

    if (counter !== undefined) {
      this.counter = counter;
    }

    if (until !== undefined) {
      const { triggerCount } = until;
      this.conditionUntilTriggercount = Number(triggerCount);
    }

    const process = () => {
      if (
        this.conditionUntilTriggercount &&
        this.counter > this.conditionUntilTriggercount
      ) {
        this.clearTimer();
      } else {
        const result = this.nextTimerArgument();
        this.app.notify(this, { counter: result.triggerCount });
        this.app.next(this, result);
      }
    };

    if (doStop) {
      this.clearTimer();
    }

    if (doStart) {
      if (this.periodic) {
        this.clearTimer();
        const timeBetween = moment.duration(
          this.periodicValue,
          this.periodicUnit
        );

        this.__timer = setInterval(process, timeBetween.asMilliseconds());
        if (immediate) {
          setImmediate(process);
        }
      } else {
        if (this.__timer) {
          this.clearTimer();
        }
        if (immediate) {
          setImmediate(process);
        } else {
          setTimeout(process, this.oneShotDelay);
        }
      }
    }

    this.running = !!this.__timer;
    this.app.notify(this, { running: this.running });
  }

  destroy() {
    this.clearTimer();
  }

  nextTimerArgument(params = {}) {
    return {
      ...params,
      triggerCount: ++this.counter,
    };
  }

  async process(params) {
    if (this.periodic || this.oneShotDelay === undefined) {
      return params;
    }
    if (this.isSleeping) {
      console.warn(
        "Processing timer service while sleeping ... process dropped"
      );
      clearTimeout(this.sleeping);
      this.sleeping = undefined;
      return params;
    }

    this.isSleeping = true;
    await sleep(this.oneShotDelay);
    this.isSleeping = undefined;

    const result = this.nextTimerArgument(params);
    this.app.notify(this, { counter: result.triggerCount });
    return result;
  }
}

module.exports = {
  serviceName,
  serviceId,
  service: Timer,
};
