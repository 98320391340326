export default function Incentive() {
  return (
    <div style={{ fontSize: "12pt", marginTop: 10 }}>
      <h2 style={{ fontSize: "14pt" }}>Join in Shaping</h2>
      <div className="hkp-lyt-vspace">
        <div>
          I'm on the brink of releasing the first beta version of my Go-based
          GraphQL-enabled remote runtime. It opens doors to creating more
          complex and advanced boards and workflows. Soon, this will be
          available to the public as a Docker image.
        </div>

        <div>
          However, for the initial phase, I'm looking for a closed group of
          passionate individuals interested in participating. If you're eager to
          be part of this journey and want to contribute, I invite you to sign
          up here. Share some basic information, and let's collaborate on
          something special together.
        </div>
      </div>
    </div>
  );
}
