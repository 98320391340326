import { createServiceUI } from "../../UIFactory";
import {
  AppImpl,
  InstanceId,
  ServiceAction,
  ServiceClass,
  ServiceImpl,
} from "../../types";
import { appendSubservice } from "./BrowserRuntimeApi";
import BrowserRuntimeScope from "./BrowserRuntimeScope";

export function createBrowserRuntimeApp(scope: BrowserRuntimeScope): AppImpl {
  return {
    getAuthenticatedUser: () => scope.authenticatedUser,
    next: (svc: InstanceId | null, result: any) => {
      return scope.next(svc, result);
    },

    getServiceById: (instanceId: string) =>
      scope.findServiceInstance(instanceId)?.[0] || null,

    sendAction: (action: ServiceAction) => {
      // the sandbox for example registers a listener
      // for these kind of actions
      const target: any = window.frameElement;
      if (target && target.onAction) {
        target.onAction(action);
      }
    },

    storeServiceData: (serviceUuid: string, key: string, value: string) => {
      localStorage.setItem(serviceDataKey(serviceUuid, key), value);
    },

    restoreServiceData: (serviceUuid: string, key: string) => {
      return (
        localStorage.getItem(serviceDataKey(serviceUuid, key)) || undefined
      );
    },

    removeServiceData: (serviceUuid: string, key: string) => {
      localStorage.removeItem(serviceDataKey(serviceUuid, key));
    },

    notify: (service: ServiceImpl, notification: any) => {
      if (service.__notificationTargets) {
        service.__notificationTargets.notify(service, notification);
      } else {
        console.warn(
          "BrowserRuntimeApp.notify no targets for",
          service.uuid,
          service.__descriptor?.serviceName
        );
      }
    },

    createSubService: (
      parent: ServiceImpl,
      service: ServiceClass,
      instanceId?: string
    ) => appendSubservice(scope, service, parent, instanceId),

    createSubServiceUI: (svc: ServiceImpl) =>
      createServiceUI(
        scope.registry!,
        "deprecated board name",
        svc,
        scope.descriptor.id,
        "deprecated userId",
        {
          resizable: false,
        }
      ),
    listAvailableServices: () => scope.registry.allowedServices(),
  };
}

function serviceDataKey(uuid: string, key: string) {
  return `service-data-${uuid}-${key}`;
}
